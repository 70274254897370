.search_widget {
  flex: 1;
  display: flex;
  align-self: stretch;
  flex-direction: row;
  align-items: center;
}

.search_control {
  position: absolute;
  right: 3em;
  display: flex;
  flex-direction: row-reverse;
}

@media screen {

  @media (max-width:667px) {
    .search_control {
      left:3em;
    }
  }

  @media (min-width:668px) {
    div[dir=ltr][class=lang] .search_control {
      left: 16em;
    }

    div[dir=rtl][class=lang] .search_control {
      left: 9.5em;
      right: 16em;
    }
  }

}

.search_control input {
  border: none;
  font-size: 1em;
  background-color: #4D4D4D;
  color: #fff;
  border-bottom: 0.01em solid #fff;
  padding: 0.5em;
  text-overflow: ellipsis;
  flex: 1;
}

.search_control input::placeholder {
  color: #fff;
  opacity: 0.5;
}

.search_widget > span {
  color: #000;
  margin: 1em;
  margin-top: 6em;
  margin-bottom: 3em;
  text-align: center;
}

.search_widget a {
  display: flex;
}

.search_widget .buttons {
  display: flex;
  justify-content: flex-end;
}
