.create_page {
  display: flex;
  flex-direction: column;
}

.create_page .options {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  margin-bottom: 2em;
  padding-bottom: 5em;
}
