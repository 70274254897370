.create_group_form {
  padding: 0;
}

.create_group_form .image_and_name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.create_group_form .image_and_name > div:last-child {
  flex:1;
}

.next {
  display: flex;
  align-items: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.next_disabled {
  opacity: 0.5;
}

.create_group_form .privacy_card {
  padding-top: 1em;
  padding-bottom: 3em;
}

.create_group_form .tags_card {
  padding-bottom: 3em;
}
