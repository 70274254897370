.entity_picker {
  color: #999999;
  font-size: 1.25em;
  display: flex;
  flex-direction: column;
}

.entity_picker .search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em;
  height: 3em;
  min-height: 3em;
}

.entity_picker .search_bar > * {
  margin: 0.5em;
}

.entity_picker .search_bar input {
  flex: 1;
  margin-right: 1em;
}

.entity_picker .pickable_entities {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  padding-bottom: 1em;
  overflow-y: auto;
  overflow-x: hidden;
}

@media (pointer: coarse) { /* cheeky trick to detect tablets and mobiles */
  .entity_picker .pickable_entities {
    overflow-y: scroll;
  }
}

.entity_picker .pickable_entity {
  flex: 1;
  border: 0.01em solid #999999;
  font-size: 0.8em;
  margin-bottom: 1em;
  padding: 0.25em;
  cursor: pointer;
}

.entity_picker .unpickable_entity {
  flex: 1;
}

.entity_picker .unpickable_entity .badge {
  opacity: 0.3;
  margin: 0.5em;
  padding: 0.5em;
  border: 0.01em solid #999999;
}

.entity_picker .pickable_entity .badge {
  margin: 0.5em;
  padding: 0.5em;
  border: 0.01em solid #999999;
}

.entity_picker .badge .info {
  font-size: 0.8em;
  color: #000;
}
