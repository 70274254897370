:global(button:not(.flex, .hidden, .MuiPopover-paper *, .ns *)) {
  display: inline-block;
  font-size: 1em;
  padding: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  margin: 0.5em;
  user-select: none;
  opacity: 1;
}

button:active {
  opacity: 0.25;
  transition: opacity 0.2s ease-in-out;
}

.positive {
  border: solid #4D4D4D;
  border-radius: 0.25em;
  background-color: #4D4D4D;
  color: #fff;
  text-decoration: none;
}

.negative {
  border: none;
  color: #4D4D4D;
  background-color: transparent;
  text-decoration: underline;
}

.active {
  cursor: pointer;
}

.inactive {
  opacity: 0.3;
}