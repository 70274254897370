.avatar_dropzone {
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1em;
}

.avatar_dropzone > * {
  margin: 0.5em;
}

.avatar_dropzone .upload {
  overflow: hidden;
  border-radius: 50%;
  width: 192px;
  height: 192px;
  position: relative;
}

.avatar_dropzone .upload img {
  width: 100%;
}

.avatar_dropzone .upload svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 192px;
  height: 192px;
}
