.checkbox {
  position: relative;
  display: flex;
  align-items: center;
}

.checkbox > span {
  font-size: 0.8em;
  color: #4D4D4D;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 0.5em;
  opacity: 0.5;
}

.checkbox .children {
  padding-top: 0.5em;
  width: 4em;
  height: 1em;
  min-width: 4em;
  min-height: 1.4em;
  display: flex;
  align-items: center;
}

.checkbox .switch {
  border-radius: 0.25em;
  display: flex;
  flex: 1;
  border: 0.1em solid #999999;
  padding: 0.1em;
}

.checkbox .switch.on {
  justify-content: flex-end;
}

.checkbox .switch.off {
  justify-content: flex-start;
  opacity: 0.5;
}

.checkbox .switch > div {
  border-radius: 0.125em;
  background-color: #999999;
  width: 1.75em;
  height: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox .switch > div > span {
  color: #fff;
  font-size: 0.7em;
}

.checkbox .input {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 2em;
  opacity: 0;
}
