button.bigbutton {
  color: #e2e2e2;
  background-color: #fff;
  border: 0.1em solid #e2e2e2;
  border-radius: 0.25em;
  display: flex;
  align-items: center;
  cursor: default;
}

div[dir=ltr][class=lang] button.bigbutton {
  padding: 0.75em;
}

div[dir=rtl][class=lang] button.bigbutton {
  padding: 0.35em;
  padding-left: 0.75em;
  padding-right: 0.75em;
}

.bigbutton span {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
