.tag_picker.entity_picker {
  color: #999999;
  font-size: 1em;
  position: relative;
}

.tag_picker.entity_picker .pickable_entities {
  position: absolute;
  z-index: 11;
  top: 3em;
  left: 4.5em;
  right: 1.5em;
  border: 0.01em solid #999999;
  background-color: #fff;
}

.tag_picker.entity_picker .pickable_entity {
  border: none;
  border-top: 0.01em solid #999999;
  padding: 1em;
  margin:0;
}

.tag_picker.entity_picker .pickable_entity:hover {
  border: none;
  border-top: 0.01em solid #999999;
  padding: 1em;
  margin:0;
  background-color: #999999;
  color: #fff;
}

.tag_picker.entity_picker .pickable_entity:first-child {
  border-top: none;
}


.tag_picker.entity_picker .search_bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5em;
  height: 3em;
  min-height: 3em;
}

.tag_picker.entity_picker .search_bar > * {
  margin: 0.5em;
}

.tag_picker.entity_picker .search_bar input {
  flex: 1;
  margin-right: 1em;
}
