.group_tools {
  display: flex;
  flex-direction: row;
}

.group_tools li {
  list-style-type: none;
  margin-left: 0.5em;
  display:flex;
}

.plusicon {
  margin-right: 10px;
  
}